import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CompletionRequest } from '@logichat/domains/common'
import { OpenAIConfig } from '@logichat/domains/openai'
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class OpenAIService {
  constructor(private http: HttpClient, private config: OpenAIConfig) {}

  createCompletion(
    data: Partial<CompletionRequest>,
  ): Observable<{ isBot?: boolean; isHuman?: boolean; error: unknown }> {
    const httpOptions = {
      headers: {
        'app-id': data.appId as string,
        'Content-Type': 'application/json',
      },
    }

    return (
      this.http
        // TODO: define CompletionResponse
        .post<object & { isBot: boolean; error: unknown }>(
          this.config.openaiUrl + `/v1/completions?key=${this.config.openaiApiKey}`,
          data,
          httpOptions,
        )
        .pipe(
          catchError((err) => {
            console.error(err)
            return of(err)
          }),
        )
    )
  }
}

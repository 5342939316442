import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { OpenAIConfig } from '@logichat/domains/openai'
import { OpenAIService } from '..'

@NgModule({
  imports: [CommonModule],
  exports: [],
})
export class OpenAIModule {
  static forRoot(config: OpenAIConfig): ModuleWithProviders<OpenAIModule> {
    return {
      ngModule: OpenAIModule,
      providers: [
        OpenAIService,
        {
          provide: OpenAIConfig,
          useValue: config,
        },
      ],
    }
  }
}
